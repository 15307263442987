<template>
  <div class="row justify-content-center" >
    <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
      <!-- NO USER -->
      <AnonymousDetails v-if="!user" v-model="value" v-model:anonymous="anonymousValue" />
      <!-- END NO USER -->
      <!-- USER -->
      <UserDetails v-else v-model="value" :sadaqahUserDonatingType="sadaqahUserDonatingType" />
      <!-- END USER -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    AnonymousDetails: defineAsyncComponent(() => import('./views/anonymousdetails/AnonymousDetails.vue')),
    UserDetails: defineAsyncComponent(() => import('./views/userdetails/UserDetails.vue'))
  },
  name: 'Wizard 1',
  props: ['modelValue', 'anonymous', 'sadaqahUserDonatingType'],
  emits: ['update:modelValue', 'update:anonymous'],
  computed: {
    ...mapGetters([
      'user'
    ]),
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    anonymousValue: {
      get () {
        return this.anonymous
      },
      set (val) {
        this.$emit('update:anonymous', val)
      }
    }
  }
}

</script>
